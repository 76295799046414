import Navbar from "../navbar/Navbar";
import React, { useEffect } from "react";
import "./committee.css";
import Footer from "../footer/Footer";
import sanjayImage from "../../assets/sanjay.jpg";
import rayuluImage from "../../assets/pcrayulu.jpg";
import nallashekhar from "../../assets/nallashekhar.png";
import dipeshjain from "../../assets/dipeshjain.jpg";
import sanjayyadav from "../../assets/sanjayyadav.jpg";
import vegad from "../../assets/vegad.jpg";
import venkata from "../../assets/venkata.jpg";
import pdbari from "../../assets/pdbari.jpg";
import vinod from "../../assets/vinod.jpg";
import ankit from "../../assets/ankit.jpg";
import aruntiwari from "../../assets/aruntiwari.jpg";
import mishra from "../../assets/mishra.jpg";
import haribabu from "../../assets/haribabu.jpg";
import gorlenaydu from "../../assets/gorlenaydu.jpg";
import navin from "../../assets/navin.jpg";
import routrayi from "../../assets/routrayi.jpg";
import mahendra from "../../assets/mahendra.jpg";
import achu from "../../assets/achu.jpg";
import sandeep_bansal from "../../assets/sandeep_bansal.png";
import dhirendra_dube from "../../assets/dhirendra_dube.png";
import vandhana_tripathi from "../../assets/vandhana_tripathi.png";
import seema_malik from "../../assets/seema_malik.png";
import unnat_sharma from "../../assets/unnat_sharma.png";
import ashish_nag from "../../assets/ashish_nag.png";
import sardar_jodindara from "../../assets/sardar_jodindara.png";
import naveen_p_sharam from "../../assets/naveen_p_sharam.png";
import ashok_kapoor from "../../assets/ashok_kapoor.png";
import vikas_pandey from "../../assets/vikas_pandey.png";
import P_Javadekar from "../../assets/P_Javadekar.jpg";
import natwar_singh from "../../assets/natwar_singh.jpg";
import Aslam_Sher_Khan from "../../assets/Aslam-Sher-Khan.jpeg";
import faggan from "../../assets/faggan.jpg";
import majeed from "../../assets/majeed.jpg";
import udit from "../../assets/udit.jpg";
import state_president from "../../assets/state_president.jpg";
import DEVENIDER_SINGH from "../../assets/DEVENIDER-SINGH.jpg";
import arnab_desai from "../../assets/arnab_desai.jpg";
import santoshi_more from "../../assets/santoshi_more.jpg";
import dhanjya_signh from "../../assets/dhanjya_signh.jpg";
import pankaj_pathak from "../../assets/pankaj_pathak.jpg";
import geeta_kumari from "../../assets/geeta_kumari.jpg";
import jesluck from "../../assets/jesluck.jpg";
import vishalDurafe from "../../assets/vishalDurafe.png";
import MITHU from "../../assets/MITHU.jpeg";

const patronMembers = [
  {
    name: "Mr. Sanjay Paswan",
    position: "MLC Bihar, Ex Union Minister",
    image: sanjayImage,
  },
  {
    name: "Mr. Prakash Javadekar",
    position: "(MP - RAJYA SABHA) EX UNION MINISTER",
    image: P_Javadekar,
  },
  // {
  //   name: "K NATWAR SINGH",
  //   position: "EX FOREIGN MINISTER",
  //   image: natwar_singh,
  // },
  {
    name: "MR. ASLAM SHER KHAN    ",
    position: "EX UNION MINISTER & HOCKEY PLAYER    ",
    image: Aslam_Sher_Khan,
  },
  {
    name: "FAGGAN SINGH KULASTE",
    position: "UNION MINISTER OF STATE    ",
    image: faggan,
  },
  {
    name: "MAJEED MENON    ",
    position: "EX MEMBER OF PARLIAMENT - RAJYA SABHA    ",
    image: majeed,
  },
  {
    name: "DR. UDIT RAJ    ",
    position: "EX MEMBER OF PARLIAMENT    ",
    image: udit,
  },
];

const nationalCommittee = [
  {
    name: "MR. NAVEEN P. SHARMA",
    position: "NATIONAL PRESIDENT    ",
    image: naveen_p_sharam,
    fit: "cover",
  },
  {
    name: "MR. SARDAR JOGINDER SINGH    ",
    position: "NATIONAL GENERAL SECRETARY    ",
    image: sardar_jodindara,
    fit: "cover",
  },
  {
    name: "MR. ASHOK KAPOOR    ",
    position: "NATIONAL GENERAL SECRETARY    ",
    image: ashok_kapoor,
    fit: "cover",
  },
  {
    name: "MR. SANDEEP BANSAL    ",
    position: "NATIONAL VICE PRESIDENT    ",
    image: sandeep_bansal,
    fit: "cover",
  },
  {
    name: "MR. P. C. RAYULU    ",
    position: "NATIONAL VICE PRESIDENT    ",
    image: rayuluImage,
    fit: "cover",
  },
  {
    name: "MR. DHIRENDRA KUMAR DUBEY    ",
    position: "NATIONAL TREASURER    ",
    image: dhirendra_dube,
    fit: "cover",
  },
  {
    name: "MRS. VANDANA TRIPATHI    ",
    position: "NATIONAL SECRETARY    ",
    image: vandhana_tripathi,
    fit: "cover",
  },
  {
    name: "DR. SEEMA MALIK    ",
    position: "NATIONAL SECRETARY    ",
    image: seema_malik,
    fit: "cover",
  },
  {
    name: "MR. N. NALLASHEKHAR    ",
    position: "NATIONAL SECRETARY    ",
    image: nallashekhar,
    fit: "unset",
  },
  // {
  //   name: "MR. VIKAS PANDEY    ",
  //   position: "NATIONAL SECRETARY & SPOKESPERSON    ",
  //   image: vikas_pandey,
  //   fit: "cover",
  // },
  {
    name: "MR. DIPESH JAIN    ",
    position: "NATIONAL SPOKESPERSON    ",
    image: dipeshjain,
    fit: "cover",
  },
  {
    name: "MR. SANJAY KUMAR YADAV    ",
    position: "NATIONAL ORGANISATION SECRETARY    ",
    image: sanjayyadav,
    fit: "cover",
  },
  {
    name: "MR. ASHISH VEGAD    ",
    position: "NATIONAL ORGANISATION SECRETARY    ",
    image: vegad,
    fit: "cover",
  },
  {
    name: "MR. VENKATA RAO MULA    ",
    position: "NATIONAL ORGANISATION SECRETARY    ",
    image: venkata,
    fit: "cover",
  },
  {
    name: "MR. UNNAT SHARMA    ",
    position: "NATIONAL ORGANISATION SECRETARY    ",
    image: unnat_sharma,
    fit: "cover",
  },
  {
    name: "MR. ASHISH NAG    ",
    position: "DEPUTY SECRETARY    ",
    image: ashish_nag,
    fit: "cover",
  },
  {
    name: "MR P.D. BARI    ",
    position: "OFFICE SECRETARY    ",
    image: pdbari,
    fit: "unset",
  },
];

const statePresidents = [
  {
    name: "MR. VINOD KINLEKAR    ",
    position: "STATE PRESIDENT GOA    ",
    image: vinod,
    fit: "cover",
  },
  {
    name: "MR. ANKIT AHLUWALIA",
    position: "STATE PRESIDENT - HIMACHAL PRADESH    ",
    image: ankit,
    fit: "cover",
  },
  {
    name: "MR. PANKAJ SOOD    ",
    position: "STATE PRESIDENT - PUNJAB    ",
    image: state_president,
    fit: "cover",
  },
  {
    name: "MR. DEVINDER SINGH SETHI (SUNNY)    ",
    position: "STATE PRESIDENT - NEW DELHI    ",
    image: DEVENIDER_SINGH,
    fit: "cover",
  },
  {
    name: "MR. ARNAB DESAI    ",
    position: "STATE PRESIDENT - GUJARAT    ",
    image: arnab_desai,
    fit: "cover",
  },
  {
    name: "MR. ARUN TIWARI ",
    position: "STATE PRESIDENT - MADHYA PRADESH    ",
    image: aruntiwari,
    fit: "unset",
  },
  {
    name: "MR. C.B. MISHRA    ",
    position: "STATE PRESIDENT - CHHATTISGARH    ",
    image: mishra,
    fit: "cover",
  },
  {
    name: "MR. RAMAGIRI HARI BABU    ",
    position: "STATE PRESIDENT - TELANGANA    ",
    image: haribabu,
    fit: "cover",
  },
  {
    name: "MR. GORLE SRINIVASA NAIDU    ",
    position: "STATE PRESIDENT - ANDHRA PRADESH    ",
    image: gorlenaydu,
    fit: "cover",
  },
  // {
  //   name: "DR. GEETHA KUMARI    ",
  //   position: "STATE PRESIDENT - TAMILNADU    ",
  //   image: geeta_kumari,
  //   fit: "cover",
  // },
  {
    name: "MR. NAVIN KAWAL    ",
    position: "STATE PRESIDENT - KARNATAKA    ",
    image: navin,
    fit: "cover",
  },
  {
    name: "MR. BIKRAM KESHRI ROUTRAY    ",
    position: "STATE PRESIDENT - ODISHA    ",
    image: routrayi,
    fit: "unset",
  },
  {
    name: "DR. MAHENDRA SINGH",
    position: "STATE PRESIDENT - RAJASTHAN    ",
    image: mahendra,
    fit: "cover",
  },
  {
    name: "MRS. SANTOSHI MORE",
    position: "STATE PRESIDENT- MAHARASHTRA    ",
    image: santoshi_more,
    fit: "cover",
  },
  {
    name: "MR. DHANAJAY SINGH    ",
    position: "STATE PRESIDENT- JHARKHAND    ",
    image: dhanjya_signh,
    fit: "cover",
  },
  {
    name: "MR. PANKAJ PATHAK     ",
    position: "STATE PRESIDENT - ASSAM    ",
    image: pankaj_pathak,
    fit: "cover",
  },
  {
    name: "MR. ACHU BAGANG    ",
    position: "STATE PRESIDENT - ARUNACHAL PRADESH",
    image: achu,
    fit: "unset",
  },
  {
    name: "MR. JESLUCK PETER    ",
    position: "STATE PRESIDENT - KERALA",
    image: jesluck,
    fit: "unset",
  },
  {
    name: "MR. VISHAL DURAFE",
    position: "PRESIDENT - MUMBAI",
    image: vishalDurafe,
    fit: "unset",
  },
  {
    name: "MRS. MITHU MUKHERJEE",
    position: "STATE PRESIDENT - WEST BENGAL",
    image: MITHU,
    fit: "unset",
  },
];

const Committee = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ScrollToPatronCommittee = () => {
    window.scrollTo(0, 150);
  };

  const ScrollToWorkingCommittee = () => {
    window.scrollTo(0, window.innerWidth > 768 ? 1000 : 2700);
  };

  const ScrollToStateCommittee = () => {
    window.scrollTo(0, window.innerWidth > 768 ? 2700 : 8300);
  };
  return (
    <>
      <Navbar />
      <div className="container-fluid mt-5 pt-5">
        <div className="row mt-5">
          <div className="col-md-4 mb-3 d-flex justify-content-center ">
            <button
              onClick={ScrollToPatronCommittee}
              className="btn btn-danger me-4"
              style={{ borderRadius: 2 }}
            >
              Honorable National Patron Member
            </button>
          </div>
          <div className="col-md-4 mb-3 d-flex justify-content-center ">
            <button
              onClick={ScrollToWorkingCommittee}
              className="btn btn-danger me-4"
              style={{ borderRadius: 2 }}
            >
              National Working Committee
            </button>
          </div>
          <div className="col-md-4 mb-3 d-flex justify-content-center ">
            <button
              onClick={ScrollToStateCommittee}
              className="btn btn-danger me-4"
              style={{ borderRadius: 2 }}
            >
              Our State Presidents
            </button>
          </div>
        </div>

        <div className="row bg-light mt-5 py-4 px-5">
          <h3 className="text-center fw-bolder ">
            HONORABLE NATIONAL PATRON MEMBER
          </h3>
          {patronMembers.map((member, index) => (
            <div className="col-md-3 mt-4" key={index}>
              <div
                className="shadow rounded-4 py-4 custom-card"
                style={{ height: 300 }}
              >
                <div
                  className="mx-auto d-block shadow"
                  style={{
                    height: 140,
                    width: 140,
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <div className="col pic">
                    <img src={member.image} alt="" />
                  </div>
                </div>
                <h6 className="text-center fw-bolder mt-4">{member.name}</h6>
                <p
                  className="text-center mt-1 opacity-75 "
                  style={{ fontSize: 13 }}
                >
                  {member.position}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="row bg-light mt-5 py-4 px-5">
          <h3 className="text-center fw-bolder ">NATIONAL COMMITTEE</h3>
          {nationalCommittee.map((member, index) => (
            <div className="col-md-3 mt-4" key={index}>
              <div className="shadow rounded-4 py-4" style={{ height: 300 }}>
                <div
                  className="mx-auto d-block shadow"
                  style={{
                    height: 140,
                    width: 140,
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <div className="col pic">
                    <img
                      style={{ objectFit: member.fit }}
                      src={member.image}
                      alt=""
                    />
                  </div>
                </div>
                <h6 className="text-center fw-bolder mt-4">{member.name}</h6>
                <p
                  className="text-center mt-1 opacity-75 "
                  style={{ fontSize: 13 }}
                >
                  {member.position}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="row bg-light mt-5 py-4 px-5">
          <h3 className="text-center fw-bolder ">OUR STATE PRESIDENTS</h3>
          {statePresidents.map((member, index) => (
            <div className="col-md-2 mt-4" key={index}>
              <div
                className="shadow rounded-4 py-4 px-2"
                style={{ height: 270 }}
              >
                <div
                  className="mx-auto d-block shadow"
                  style={{
                    height: 100,
                    width: 100,
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <div className="col pic">
                    <img
                      style={{ objectFit: member.fit }}
                      src={member.image}
                      alt=""
                    />
                  </div>
                </div>
                <h6 className="text-center fw-bolder mt-4">{member.name}</h6>
                <p
                  className="text-center mt-1 opacity-75 "
                  style={{ fontSize: 13 }}
                >
                  {member.position}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Committee;
